// DataContext.js - Create a context to hold shared data
import React, { createContext, useState, useContext } from 'react';

export const DataContext = createContext();

export const DataProvider = ({ children }) => {
  const [sharedData, setSharedData] = useState('');

  const updateData = (data) => {
    setSharedData(data);
  };

  return (
    <DataContext.Provider value={{ sharedData, updateData, setSharedData }}>
      {children}
    </DataContext.Provider>
  );
};

export const useData = () => {
    const context = useContext(DataContext);
    if (!context) {
      throw new Error('useData must be used within a DataProvider');
    }
    return context;
};
