import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import { Container, Box, Typography, Tabs, Tab } from "@mui/material";
import Managment from "../components/managment";
import "../styles/About.css";

const StyledTabs = styled((props) => (
  <Tabs
    {...props}
    TabIndicatorProps={{ children: <span className="MuiTabs-indicatorSpan" /> }}
  />
))({
  "& .MuiTabs-indicator": {
    display: "flex",
    justifyContent: "center",
    backgroundColor: "transparent",
  },
  "& .MuiTabs-indicatorSpan": {
    width: "100%",
    backgroundColor: "#17a2b8",
  },
});

const StyledTab = styled((props) => <Tab disableRipple {...props} />)(
  ({ theme }) => ({
    textTransform: "none",
    fontFamily: `'Ubuntu', sans-serif`,
    fontSize: "24px",
    marginRight: "20px",
    color: "#17a2b8",
    "&.Mui-selected": {
      color: "black",
    },
    "&.Mui-focusVisible": {
      backgroundColor: "rgba(100, 95, 228, 0.32)",
    },
  })
);

const CustomTabPanel = (props) => {
  const { children, value, index, ...other } = props;
  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 0 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
};

CustomTabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const getTabIndexFromUrl = (pathname) => {
  const match = pathname.match(/\/our-team\/(\d+)/);
  if (match) {
    const tabIndex = parseInt(match[1]);
    return tabIndex >= 0 && tabIndex <= 2 ? tabIndex : 0; // Ensure the tab index is within range
  }
  return 0; // Default to the first tab if no parameter is found
};

const OurTeam = () => {
  const location = useLocation();
  const [value, setValue] = useState(getTabIndexFromUrl(location.pathname));
  const [loading, setLoading] = useState(true);
  const [data1, setData1] = useState([]);
  const [data2, setData2] = useState([]);
  const [data3, setData3] = useState([]);

  useEffect(() => {
    setValue(getTabIndexFromUrl(location.pathname)); // Update tab value when location changes
  }, [location.pathname]);

  useEffect(() => {
    fetch("https://hashtech.pythonanywhere.com/api/employees/")
      .then((response) => response.json())
      .then((data) => {
        const newData1 = [];
        const newData2 = [];
        const newData3 = [];

        for (var i = 0; i < data.length; i++) {
          var e_type = data[i]["employee_type"];
          const newData = {
            id: i + 1,
            img: data[i]["image"],
            name: data[i]["name"],
            text: data[i]["designation"],
            desc: data[i]["intro"],
            linked: data[i]["linkedin_profile"],
          };

          if (e_type === "India") {
            newData2.push(newData);
          } else if (e_type === "US") {
            newData1.push(newData);
          } else if (e_type === "Directors") {
            newData3.push(newData);
          }
        }

        setData1(newData1);
        setData2(newData2);
        setData3(newData3);
        setLoading(false);
      })
      .catch((error) => {
        console.error("Error fetching data:", error);
        setLoading(false);
      });

    window.scroll(0, 0);
  }, [location.pathname]); // Trigger useEffect when the location.pathname changes

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  if (loading) {
    return <div>Loading...</div>;
  }


  return (
    <>
      <div id="image-team" className="home" style={{ marginBottom: "50px" }}>
      <Typography
        variant="h1"
        align="center"
        className="text-light"
        sx={{ fontFamily: `'Ubuntu', sans-serif`, fontSize: "65px", '@media (max-width: 600px)': { fontSize: "40px" } }}
      >
        Bringing Out the Best
      </Typography>
      <Typography
        variant="h1"
        align="center"
        sx={{ fontFamily: `'Ubuntu', sans-serif`, fontSize: "65px", color: "#17a2b8", '@media (max-width: 600px)': { fontSize: "40px" } }}
      >
        in Each Other!
      </Typography>
      </div>

      <Box sx={{ width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <StyledTabs
            value={value}
            onChange={handleChange}
            aria-label="basic tabs example"
          >
            <StyledTab label="USA" {...a11yProps(0)} />
            <StyledTab label="India" {...a11yProps(1)} />
            <StyledTab label="Directors" {...a11yProps(2)} />
          </StyledTabs>
        </Box>
        <Container>
          <CustomTabPanel value={value} index={0} sx={{ color: "black" }}>
            <Managment details={data1} />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={1} id="tabheading">
            <Managment details={data2} />
          </CustomTabPanel>
          <CustomTabPanel value={value} index={2} id="tabheading">
            <Managment details={data3} />
          </CustomTabPanel>
        </Container>
      </Box>
    </>
  );
};

export default OurTeam;
